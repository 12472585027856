import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { ChevronDoubleRightIcon } from "@heroicons/react/solid"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
}

const CallToActionSection = () => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    threshold: 0.4,
  })

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])

  return (
    <motion.section
      className="bg-blue-50"
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={containerVariants}
    >
      <div className="container mx-auto max-w-6xl px-6 py-24 text-center">
        <h1 className="font-medium text-3xl leading-relaxed tracking-tight">
          We'd love to build something
          <br />
          amazing together!
        </h1>
        <button
          className="mt-8 py-4 px-6 border-2 border-gray-300 flex justify-center items-center space-x-2 mx-auto hover:bg-white transition duration-200 ease-in-out"
          onClick={() => navigate("/contact")}
        >
          <span>Start a project</span>
          <ChevronDoubleRightIcon className="w-4 h-4 text-gray-400" />
        </button>
      </div>
    </motion.section>
  )
}

export default CallToActionSection
