import React from "react"
import { navigate } from "gatsby"
import {
  ChevronDoubleRightIcon,
  CogIcon,
  CursorClickIcon,
  DocumentSearchIcon,
  DesktopComputerIcon,
  HandIcon,
  VolumeUpIcon,
} from "@heroicons/react/solid"

const services = [
  {
    ServiceIcon: CogIcon,
    name: "Performance",
    description:
      "Our goal is to improve the facilis est et expedita distinctio. Namerome libero tempore, cum soluta nobis est eligendi.",
  },
  {
    ServiceIcon: CursorClickIcon,
    name: "Usability",
    description:
      "We always try making facilis est et expedita quisquam venit libero tempore, cum soluta nobis est eligendi optio.",
  },
  {
    ServiceIcon: DocumentSearchIcon,
    name: "Search Engine Optimization",
    description:
      "Before asking why is facilis est et expedita sed duelit esteros libero tempore, cum soluta nobis est eligendi.",
  },
  {
    ServiceIcon: DesktopComputerIcon,
    name: "Usability",
    description:
      "We always try making facilis est et expedita quisquam venit libero tempore, cum soluta nobis est eligendi optio.",
  },
  {
    ServiceIcon: HandIcon,
    name: "Accessibility",
    description:
      "Before asking why is facilis est et expedita sed duelit esteros libero tempore, cum soluta nobis est eligendi.",
  },
  {
    ServiceIcon: VolumeUpIcon,
    name: "Performance",
    description:
      "Our goal is to improve the facilis est et expedita distinctio. Namerome libero tempore, cum soluta nobis est eligendi.",
  },
]

const ServiceSection = () => {
  return (
    <section className="border-b">
      <div className="container mx-auto max-w-6xl px-6 py-28">
        <div className="flex flex-wrap justify-between items-center">
          <h1 className="font-medium text-3xl leading-relaxed tracking-tight w-full md:w-auto">
            We can take your business
            <br />
            to a whole new level.
          </h1>
          <button
            className="mt-6 md:mt-0 py-4 px-6 border-2 flex justify-center items-center space-x-2 hover:bg-blue-50 transition duration-200 ease-in-out"
            onClick={() => navigate("/contact")}
          >
            <span>Start a project</span>
            <ChevronDoubleRightIcon className="w-4 h-4 text-gray-400" />
          </button>
        </div>
        <div className="mt-14">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-14">
            {services.map(({ ServiceIcon, name, description }, index) => (
              <div key={`service-${index}`} className="space-y-3">
                <ServiceIcon className="w-8 h-8 text-gray-700" />
                <h3 className="text-md text-gray-400">{name}</h3>
                <p className="leading-relaxed">{description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServiceSection
