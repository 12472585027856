import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PortfolioSection = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulTeam {
        nodes {
          id
          name
          role
          image {
            gatsbyImageData(layout: CONSTRAINED, placeholder: TRACED_SVG)
          }
        }
      }
    }
  `)

  const teams = data?.allContentfulTeam?.nodes ?? []

  return (
    <section>
      <div className="container mx-auto max-w-6xl px-6 py-28">
        <div>
          <h1 className="font-medium text-3xl leading-relaxed tracking-tight">
            Meet the team.
          </h1>
        </div>
        <p className="mt-3 leading-relaxed">
          We are a team sit amet consectetur adipiscing elit
          <br />
          sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
        <div className="mt-14 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
          {teams.map(({ id, name, role, image }) => (
            <div key={id} className="text-center">
              <div>
                <GatsbyImage image={getImage(image)} alt={name} />
              </div>
              <h4 className="text-sm text-gray-400 mt-4">{role}</h4>
              <h3 className="mt-2">{name}</h3>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default PortfolioSection
