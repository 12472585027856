import React from "react"
import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { ChevronDoubleRightIcon } from "@heroicons/react/solid"

const HeroSection = () => (
  <>
    <section className="min-h-[60vh] flex items-center">
      <div className="container mx-auto max-w-6xl px-6 py-10">
        <h1 className="font-medium text-5xl leading-normal tracking-tighter">
          We believe in designing
          <br />
          beautiful &amp; clean user interfaces.
        </h1>
        <p className="mt-5 leading-loose">
          We are a small remote cum soluta nobis est eligendi optio cumque nihil
          impedit
          <br />
          quo minus id quod maxime placeat facere possimus.
        </p>
        <button
          className="mt-8 py-4 px-6 border-2 flex justify-center items-center space-x-2 hover:bg-blue-50 transition duration-200 ease-in-out"
          onClick={() => navigate("/")}
        >
          <span>Our work</span>
          <ChevronDoubleRightIcon className="w-4 h-4 text-gray-400" />
        </button>
      </div>
    </section>
    <section>
      <StaticImage
        src="../../assets/images/about-hero.jpg"
        placeholder="blurred"
        layout="fullWidth"
        alt="about hero image"
      />
    </section>
  </>
)

export default HeroSection
