import React from "react"

import Seo from "../components/Seo"
import Layout from "../components/Layout"
import HeroSection from "../components/AboutPage/HeroSection"
import ServiceSection from "../components/AboutPage/ServiceSection"
import TeamSection from "../components/AboutPage/TeamSection"
import CallToActionSection from "../components/CallToActionSection"

const AboutPage = () => {
  return (
    <Layout>
      <Seo title="About" />
      <HeroSection />
      <ServiceSection />
      <TeamSection />
      <CallToActionSection />
    </Layout>
  )
}

export default AboutPage
